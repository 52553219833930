
import Vue from 'vue';
import {mapActions, mapMutations} from "vuex";
import Notice from "~/entities/Notice";

export default Vue.extend({
  data() {
    return {
      email: '',
      password: '',
      submitted: false as boolean,
      authError: false as boolean,
      actionNotice: null as Notice | null
    }
  },
  methods: {
    ...mapMutations({
      setLoading: 'setLoading',
    }),
    ...mapActions({
      loginUser: 'user/loginUser',
    }),
    isValidEmail(){
      let validEmail = this.$refs.loginEmail as HTMLInputElement
      return validEmail ? validEmail.checkValidity() : false;
    },
    async submitLogin(){
      if(this.email && this.password){
        let login = await this.loginUser({email: this.email, password: this.password});
        if(login){
          //emit confirmation
          this.$emit('confirmationMade');
          this.submitted = false;
        }else{
          this.submitted = true;
          this.authError = true;
          (this as any).actionNotice = new Notice({ expletive:'Sorry', message:'You have entered an invalid username or password', type:'alert-danger', dismissable: false });
        }
      }else{
        this.submitted = true;
        this.authError = true;
      }
    },
    resetVal() {
      this.authError = false
    }
  }
});
